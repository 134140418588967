.from-home {
    color: white;
}

/* .card-header {
    height: 1vw;
    background: rgb(16, 0, 43);
    background: linear-gradient(180deg, rgba(16, 0, 43, 1) 0%, rgba(36, 0, 70, 1) 87%);
}

.card-grid {
    display: flex;
    height: .9vw;
    background: rgb(36, 0, 70);
    background: linear-gradient(180deg, rgba(36, 0, 70, 1) 0%, rgba(60, 9, 108, 1) 100%);
}

.card-grid-left {
    width: 40vw;
    height: 1vw;
    background-color: rgba(7, 25, 49, 1);
    border-top-right-radius: 1vw;
}

.card-grid-center {
    width: 20vw;
    height: 1.5vw;
    background: rgb(36, 0, 70);
    background: linear-gradient(180deg, rgba(36, 0, 70, 1) 0%, rgba(60, 9, 108, 1) 100%);
    box-shadow: 0 .2vw #00081442;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
}

.card-grid-right {
    width: 40vw;
    height: 1vw;
    background-color: rgba(7, 25, 49, 1);
    border-top-left-radius: 1vw;
    box-shadow: 0 0 0 #00081442;
} */


/* css body */
.card-body {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 100px;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.logo-home {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 600px;
}

.card-login-discord {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    /* background: linear-gradient(0deg, rgba(36, 0, 70, 1) 0%, rgba(60, 9, 108, 1) 100%); */
    color: black;
    border-radius: 1vw;
    margin-top: 10vw;
    margin-left: auto;
    margin-right: auto;
    width: 14vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.logo-discord {
    width: 7vw;
    transition: .3s;
}

.logo-discord:hover {
    width: 7.5vw;
    cursor: pointer;
    transition: .3s;
}

.card-section-product {
    background: rgb(217, 150, 8);
    background: linear-gradient(0deg, rgba(217, 150, 8, 1) 17%, rgba(206, 218, 2, 1) 100%);
}

.view-all-product {
    color: white;
    text-decoration: none;
    font-size: 19px;
}

.view-all-product span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
    color: white;
}

.view-all-product span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.view-all-product:hover span {
    padding-right: 25px;
}

.view-all-product:hover span:after {
    opacity: 1;
    right: 0;
}

.icon-spinner {
    color: white;
}