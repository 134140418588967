@import url('https://fonts.googleapis.com/css2?family=Inter&family=Noto+Sans+Thai:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-family: 'Noto Sans Thai', sans-serif;
}

.from-navbar {
    display: flex;
    justify-content: end;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    padding: .5vw;
    /* background-color: #0f1c2e; */
    background: rgb(225, 124, 255);
    background: linear-gradient(90deg, rgba(225, 124, 255, 1) 0%, rgba(201, 42, 255, 1) 0%, rgba(169, 93, 209, 1) 0%, rgba(86, 0, 144, 1) 0%, rgba(18, 4, 28, 1) 100%);
    z-index: 999;
}

.card-logo {
    display: none;
}

.navbar-logo {
    position: absolute;
    top: 80px;
    left: -4.5vw;
    background-color: rgba(255, 255, 255, 0.212);
    max-width: 100%;
    max-height: 100%;
    color: white;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: .3s;
    text-decoration: none;
}

.navbar-logo:hover {
    position: absolute;
    left: 0;
    width: 8vw;
    height: 3.3vw;
    transition: .3s;
}

.navbar-logo p {
    position: relative;
    left: 1vw;
    font-size: 1vw;
}

.logo {
    width: 90px;
    object-fit: cover;
    position: relative;
    left: .7vw;
    top: .1vw;
}

.active-navbar-logo {
    position: absolute;
    left: 0;
    width: 8vw;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.navbar-home {
    color: white;
    text-decoration: none;

    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 800;
    overflow: hidden;
    background: linear-gradient(to right, #b8c0ff, #b8c0ff 50%, white 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;
}

.navbar-home:hover {
    background-position: 0 100%;
}

.active-navbar-home {
    border-bottom: .1vw solid #b8c0ff;
    margin-top: .1vw;
}

.menu {
    display: none;
}

.section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 2vw;
    color: white;
    padding: .5vw;
    border-radius: 1vw;
    gap: 1vw;
    /* margin-left: 70vw; */
}

.navbar-login {
    color: white;
    text-decoration: none;
    font-size: 19px;
}

.navbar-login span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
    color: white;
}

.navbar-login span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.navbar-login:hover span {
    padding-right: 25px;
}

.navbar-login:hover span:after {
    opacity: 1;
    right: 0;
}

.icon-navbar {
    display: none;
}

.icon-user-login {
    color: #474448;
}