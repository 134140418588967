// @media only screen and (min-width: 801px) and (max-width: 1920px) {}
// @media only screen and (min-width: 320px) and (max-width: 800px) {}


//? css navbar
.from-navbar {
    @media only screen and (min-width: 0px) and (max-width: 800px) {
        display: flex;
        justify-content: space-between;
        padding: 20px;

        .menu {
            display: block;
        }

        .icon-menu {
            color: white;
        }

        .card-logo {
            display: block;
            color: white;

            & span {
                display: flex;
                align-items: center;
                gap: 1vw;

                & p {
                    font-size: 14px;
                }

                & .logo {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .navbar-logo {
            display: none;
        }

        .section {
            width: 100%;
            height: 100vh;
            display: flex;
            position: absolute;
            flex-direction: column;
            align-content: center;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            top: 60px;
            left: 30%;
            transition: .5s ease;
            opacity: 0;
            background-color: transparent;
            backdrop-filter: blur(10px);
            margin-left: 70vw;

            &.active {
                background-color: transparent;
                backdrop-filter: blur(10px);
                left: 10px;
                opacity: 1;
                z-index: 1;
                align-content: center;
                align-items: flex-start;
                justify-content: flex-start;
                transition: .5s ease;
                height: 100vh;
                padding: 20px;
                margin-left: 70vw;
            }

            & .navbar-home {
                font-size: 15px;

                & p {
                    display: none;
                }

                & .icon-navbar {
                    font-size: 10px;
                    display: block;
                    padding: 10px;
                    border: 1px solid rgba(255, 255, 255, 0.11);
                    border-radius: 5px;
                }
            }

            & .navbar-login {
                font-size: 15px;

                & span {
                    display: none;
                }

                & .icon-navbar {
                    font-size: 12px;
                    display: block;
                    padding: 10px;
                    border: 1px solid rgba(255, 255, 255, 0.11);
                    border-radius: 5px;
                }
            }

            & .active-navbar-home {
                border-bottom: none;
            }

            & .text-name {
                display: none;
            }

            & .icon-logout {
                position: absolute;
                top: 80%;
                left: 35px;
                font-size: 12px;
            }

            & .image-user {
                position: absolute;
                top: 22%;
                left: 27px;
                width: 28px !important;
                height: 28px !important;

                & img {
                    width: 28px !important;
                    height: 28px !important;
                }

                & .icon-user-login {
                    width: 28px !important;
                    height: 28px !important;
                }
            }
        }

        .bookmark {
            display: none;
        }

    }
}

//? css home
.card-body {
    @media only screen and (min-width: 0px) and (max-width: 800px) {
        width: 100% !important;
        display: grid;
        padding: 0 !important;
        grid-template-columns: none !important;
        padding-top: 100px !important;
        margin-top: 0 !important;

        & .logo-home {
            width: 210px !important;
        }

        & span {
            display: flex !important;
            padding: 20px;
            gap: 2vw !important;

            & h1:nth-child(1) {
                display: none;
            }

            & .text-haeder {
                display: none;
            }

            & .card-login-discord {
                width: auto;
                padding: 2vw !important;
                margin-top: 20px;
                border-radius: 2vw;

                & span:nth-child(1) {
                    bottom: 90% !important;
                    left: 8px !important;
                    border-radius: 5px !important;
                    padding-left: 1vw !important;
                    padding-right: 1vw !important;

                    & p {
                        font-size: 12px !important;
                    }

                    & .icon-turndown {
                        font-size: 12px !important;
                    }
                }

                & span:nth-child(2) {
                    width: calc(100% - 0px);
                    padding: 2vw !important;
                    border-radius: 2vw !important;
                    padding-left: 8vw !important;
                    padding-right: 8vw !important;

                    & img:nth-child(1) {
                        width: 9vw !important;
                    }

                    & img:nth-child(2) {
                        width: 20vw !important;
                    }
                }
            }
        }
    }
}

.grid-script {
    @media only screen and (min-width: 0px) and (max-width: 800px) {
        width: 90vw !important;
        padding-left: 1vw;
        padding-right: 1vw;

        & span {
            display: flex !important;
            justify-content: space-between;
            width: 100% !important;
        }

        & .card-section-product {
            width: auto !important;
            border-radius: 50px !important;

            & h2 {
                font-size: 13px !important;
                padding: 1vw !important;
                padding-left: 3vw !important;
                padding-right: 3vw !important;
            }
        }

        & .view-all-product {
            font-size: 12px !important;
            position: relative !important;
            display: flex;
            justify-content: end;
            right: 10px !important;
        }

        & .grid-product {
            display: flex !important;
            flex-direction: row;
            gap: 20px !important;
            overflow: hidden;
            overflow-x: scroll;
            padding: 4vw !important;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        & .card-product {
            width: 75vw !important;
            padding: 2vw !important;
            border-radius: 2vw !important;

            & .leyout-image {
                width: 100% !important;

                & img {
                    width: 100% !important;
                    border-radius: 2vw !important;
                    height: 100% !important;
                }
            }

            & .card-details {
                border-radius: 2vw !important;
                width: 71vw !important;
                padding: 2vw !important;
                // height: 51% !important;
                max-height: 100% !important;
                // margin-top: 10px !important;

                & p:nth-child(1) {
                    font-size: 25px !important;
                }

                & p:nth-child(2) {
                    font-size: 15px !important;
                    padding-left: 5px !important;
                    padding-right: 5px;
                }

                & div {
                    width: 80px !important;
                    left: -5vw !important;
                    padding-right: 5px !important;
                    height: 30px !important;

                    & .text-price {
                        font-size: 15px !important;
                    }
                }

            }
        }
    }
}

//? css view script
.from-view-product {

    /* Remove the min-width and max-width conditions */
    @media only screen {
        & .pay-script {
            position: absolute;
            width: 25%;
            height: 100vh;
            top: 0;
            right: 0;
            left: 0;
            background-color: transparent;
            backdrop-filter: blur(10px);
            padding-top: 100px;
            margin: 0 auto;
            padding-left: 15px;
            padding-right: 15px;
            color: white;
            opacity: 0;
            transition: .5s ease;
            margin-left: 74vw;
            z-index: -1;

            &.active {
                background-color: transparent;
                backdrop-filter: blur(10px);
                left: -10px;
                opacity: 1;
                z-index: 1;
                align-content: center;
                align-items: flex-start;
                justify-content: flex-start;
                transition: .5s ease;
                width: 25%;
                height: 100vh;
                padding-top: 100px;
                margin: 0 auto;
                padding-left: 15px;
                padding-right: 15px;
                margin-left: 75vw;
            }

            & .icon-close-pay {
                cursor: pointer;
            }

            & .payment {
                font-size: 15px;
                width: 30%;
                transition: .3s;

                &.active {
                    background: linear-gradient(0deg, rgb(56, 0, 109) 0%, rgb(109, 22, 190) 100%);
                    padding: 5px;
                    border-radius: 8px;
                    transition: .3s;
                }

                &:hover {
                    background: linear-gradient(0deg, rgb(56, 0, 109) 0%, rgb(109, 22, 190) 100%);
                    padding: 5px;
                    border-radius: 8px;
                    transition: .3s;
                }
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 800px) {
        position: relative !important;

        & .grid-view {
            padding: 10px !important;
            padding-top: 70px !important;

            & p:nth-child(1) {
                font-size: 20px !important;
            }

            & .card-price {
                width: 65px !important;

                & p:nth-child(1) {
                    font-size: 15px !important;
                }
            }

            & .card-pay {
                padding: 3px !important;
                border-radius: 5px !important;

                & p {
                    font-size: 15px !important;
                    padding-top: 2px !important;
                }
            }

            & .view-script {
                flex-direction: column;
                padding-left: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-top: 0 !important;
                // max-width: 100vw !important;

                & img {
                    width: 100% !important;
                    height: 100% !important;
                    border-radius: 10px !important;
                    margin-left: auto;
                    margin-right: auto;
                }

                & .video-player {
                    margin-top: 20px;
                    margin-left: auto;
                    margin-right: auto;

                    & iframe {
                        max-width: 100% !important;
                        max-height: 100% !important;
                        border-radius: 10px !important;

                    }

                    & .card-description {
                        width: 100% !important;
                        height: 100% !important;
                        border-radius: 10px !important;
                        margin-top: 20px !important;

                        & div:nth-child(1) {
                            width: 122px !important;
                        }

                        & div:nth-child(2) {
                            width: 100% !important;
                            height: 100% !important;
                            padding: 10px !important;

                            & p {
                                font-size: 15px !important;
                            }
                        }
                    }
                }

            }

            & .pay-script {
                position: absolute;
                width: 100%;
                height: auto;
                top: 0;
                right: 0;
                left: 0;
                background-color: transparent;
                backdrop-filter: blur(10px);
                padding-top: 100px;
                margin: 0 auto;
                padding-left: 15px;
                padding-right: 15px;
                color: white;
                opacity: 0;
                transition: .5s ease;
                // margin-left: 55vw;
                z-index: -1;

                &.active {
                    background-color: transparent;
                    backdrop-filter: blur(10px);
                    opacity: 1;
                    z-index: 1;
                    align-content: center;
                    align-items: flex-start;
                    justify-content: flex-start;
                    transition: .5s ease;
                    width: 100%;
                    // overflow: hidden;
                    height: 100%;
                    padding-top: 70px;
                    padding-left: 15px;
                    padding-right: 15px;

                    & .pay-details {
                        padding-left: 15px !important;
                        padding-right: 15px !important;

                        & p:nth-child(1) {
                            font-size: 14px !important;
                        }

                        & .btn-choose-pay {
                            padding-left: 15px !important;
                            padding-right: 15px !important;

                            & .payment {
                                width: 40%;
                            }
                        }


                        & .card-choose-pay {
                            height: 100% !important;

                            & .card-img-qr {
                                max-width: 100% !important;
                                width: 50% !important;
                                height: 50% !important;


                                & .img-qr {
                                    // width: 50% !important;
                                    // height: 50% !important;
                                    object-fit: cover !important;
                                    object-position: top !important;
                                }
                            }

                            & .img-wallet {
                                width: 40% !important;
                            }
                        }

                    }

                    & .pay-details-script {
                        padding-left: 15px !important;
                        padding-right: 15px !important;

                        & p:nth-child(1) {
                            font-size: 14px !important;
                        }

                        & div p:nth-child(1),
                        p:nth-child(2) {
                            font-size: 18px !important;
                        }
                    }

                }

                & .payment {
                    font-size: 15px;
                    width: 30%;
                    transition: .3s;

                    &.active {
                        background: linear-gradient(0deg, rgb(56, 0, 109) 0%, rgb(109, 22, 190) 100%);
                        padding: 5px;
                        border-radius: 8px;
                        transition: .3s;
                    }

                    &:hover {
                        background: linear-gradient(0deg, rgb(56, 0, 109) 0%, rgb(109, 22, 190) 100%);
                        padding: 5px;
                        border-radius: 8px;
                        transition: .3s;
                    }
                }
            }
        }

    }
}

//? css all script
.from-product {
    & .card-header {
        background: rgb(217, 150, 8);
        background: linear-gradient(0deg, rgba(217, 150, 8, 1) 17%, rgba(206, 218, 2, 1) 100%);
    }

    @media only screen and (min-width: 0px) and (max-width: 800px) {
        padding-top: 60px;

        & .header {
            padding-top: 5% !important;
        }

        & .card-header {
            width: 40% !important;
            border-radius: 50px !important;

            & p {
                font-size: 15px !important;
            }
        }

        & .grid-product {
            flex-direction: column;
            padding-left: 15px !important;
            padding-right: 15px !important;

            & .card-product {
                width: 100% !important;
                border-radius: 10px !important;
                padding: 8px !important;

                & img {
                    width: 100% !important;
                    border-radius: 10px !important;
                    height: 100% !important;
                }

                & .card-details {
                    border-radius: 2vw !important;
                    width: 100% !important;
                    padding: 2vw !important;
                    margin-top: 10px !important;

                    & p:nth-child(1) {
                        font-size: 25px !important;
                    }

                    & p:nth-child(2) {
                        font-size: 15px !important;
                        padding-left: 10px !important;
                        padding-right: 5px;
                    }

                    & div {
                        width: 80px !important;
                        left: -5vw !important;
                        padding-right: 5px !important;
                        height: 30px !important;


                        & .text-price {
                            font-size: 15px !important;
                        }
                    }

                }
            }
        }
    }
}

//? css profile
.from-profile {
    @media only screen and (min-width: 801px) and (max-width: 1920px) {
        & .models:focus {
            transition: 1s;
        }

        & .list-scrip {
            &::-webkit-scrollbar {
                width: .2vw;
                background-color: #000000d8;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgb(143, 143, 143);
                border-radius: 1vw;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #555;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 800px) {
        // width: 100% !important;
        padding-top: 70px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;

        & .img-found {
            width: 100% !important;
            overflow: none !important;
        }

        & .teble {
            padding-left: 5px !important;
            padding-right: 5px !important;

            & .list-scrip {
                height: 100% !important;
            }

            & li {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }

            & .date-scrip {
                font-size: 10px !important;
            }

            & .name-scrip {
                font-size: 12px !important;
            }

            & .text-ip {
                text-align: right !important;
            }

            & .ip-scrip {
                font-size: 11px !important;
            }

            & .icon-pen {
                font-size: 11px !important;
            }

            & .download {
                display: none;
            }
        }

        & .models {
            display: flex;
            justify-content: center;
            align-items: center;

            & .card-models {
                position: absolute;
                transform: translate(-50%, -50%);
                max-width: 100% !important;
                top: 50% !important;
                left: 50% !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);

                & .icon-warm {
                    font-size: 30px !important;
                }

                & .btn-models {
                    font-size: 13px !important;
                }
            }
        }

        & .widget {
            display: none;
        }


    }
}